import React, { useContext } from 'react';
import { AppContext } from '../AppContext';
import { Link } from 'react-router-dom';

interface Props {

}

const Footer = (props: Props) => {

    const context = useContext(AppContext);

    return (
        <div style={{ marginTop: 75 }} className='mb-2 d-flex justify-content-center align-items-center flex-column'>
            <img width={300} src='https://media.sandhills.com/cdn/images/vip/logo-light.png' />
            <p className='mt-3 mb-0 text-light-gray text-upper text-cond fw-bold'> {context.translations.get('Sandhills.copyrightsandhillsdate').replace('{0}', new Date().getFullYear().toString())}</p>
            <div className='text-light-gray text-cond fw-bold'>
                <Link to='/privacypolicy'>{context.translations.get('sandhills.tradepubwebsite.mvc.privacypolicy')}</Link>
                <i className='border border-light-gray mx-3' />
                <Link to='/cookiepolicy'>{context.translations.get('sandhills.cookiepolicy')}</Link>
                <i className='border border-light-gray mx-3' />
                <Link to='https://www.fleetevaluator.com/terms-of-use/'>{context.translations.get('sandhills.TermsOfUse')}</Link>
            </div>
        </div>
    );
}

export default Footer;